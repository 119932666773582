import React, { useState, useEffect } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter";
import { ReactComponent as AddIcon } from "../../../../assets/images/add.svg";
import BaseTable from "../../../../components/table/BaseTable";
import { ReactComponent as DotIcon } from "../../../../assets/images/threedots.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { showToast } from "../../../../util/toasts";
import InviteNewUserModal from "../InviteNewUserModal";
import DeleteModal from "../../../../components/modal/DeleteModal";
import axios from "../../../../util/axios";
import { formatDate } from "../../../../util/formatDate.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { adminInvitiationsTabsConfig } from "../../../../util/tabsConfig.js";
import InvitationModal from "../InvitationModal.js";

const Invitaion = () => {
  const [popoverId, setPopoverId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [allInvitiations, setAllInvitiations] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const closeSuccessModal = () => setShowSuccessModal(false);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (org) => {
    const emails = [
      ...new Set(org.filter((el) => el?.email).map((el) => el.email)),
    ].map((email) => ({
      id: email,
      name: email,
      type: "Email: " + email,
      key: "1",
      active: false,
    }));

    const organizations = [
      ...new Set(
        org
          .filter((el) => el?.organization_name)
          .map((el) => el.organization_name)
      ),
    ].map((organization_name) => ({
      id: organization_name,
      name: organization_name,
      type: "Organization: " + organization_name,
      key: "2",
      active: false,
    }));

    const invites = [
      ...new Set(org.filter((el) => el?.invites).map((el) => el.invites)),
    ].map((invites) => ({
      id: invites,
      name: invites,
      type: "# Invites: " + invites,
      key: "3",
      active: false,
    }));
    const expired = [
      ...new Set(org.filter((el) => el?.Expired).map((el) => el.Expired)),
    ].map((expired) => ({
      id: expired,
      name: expired,
      type: "Expired: " + expired,
      key: "4",
      active: false,
    }));

    const userAccess = [
      {
        id: "Desktop App",
        name: "Desktop App",
        type: "User Access: Desktop App",
        key: "5",
        active: false,
      },
      {
        id: "Mobile App",
        name: "Mobile App",
        type: "User Access: Mobile App",
        key: "5",
        active: false,
      }
    ];

    setTabs(
      adminInvitiationsTabsConfig({
        emails,
        organizations,
        invites,
        expired,
        userAccess,
      })
    );
  };

  const fetchInvitiations = async () => {
    try {
      const { data } = await axios.get("invitations", {
        params: { page: 1, pageSize: 1000 },
      });
      if (data?.items?.length > 0) {
        setAllInvitiations(data?.items);
        updateTabsWithData(data?.items);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);

  const handleShowPopover = (id) => {
    setPopoverId((prevId) => (prevId === id ? null : id));
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setPopoverId(null);
  };

  const handleResend = async (row) => {
    try {
      const { data } = await axios.post("invite", {
        users: [
          {
            email: row?.email,
            organization_id: row?.organization_id,
            user_role: row?.role,
          },
        ],
      });
      fetchInvitiations();
      setShowSuccessModal(true);
      setPopoverId(null);
    } catch (error) {
      setPopoverId(null);
      const message = error?.response?.data?.[0]?.msg;
      if (message && row?.organization_id) {
        showToast(message, "error");
      } else {
        showToast("Something went wrong!", "error");
      }
      console.error("Error:", error);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await axios.put(`expire-invitation?id=${deleteId}`, {});
      fetchInvitiations();
      setShowDeleteModal(false);
      showToast("Invitation Deleted Successfully", "success");
    } catch (error) {
      setShowDeleteModal(false);
      console.error("Error:", error);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const columns = [
    { Header: "Email", accessor: "email", isSortable: true },
    { Header: "Organization", accessor: "organization_name", isSortable: true },
    {
      Header: "Date of invitation",
      isSortable: true,
      key: "created_at",
      accessor: (row) => {
        return <span>{formatDate(row?.created_at)}</span>;
      },
    },
    { Header: "# Invites", accessor: "invites", isSortable: true },
    { Header: "Expired", accessor: "Expired", isSortable: true },
    {
      Header: "User Access",
      key: "user_access",
      isSortable: true,
      accessor: (row) => {
        return row?.inv_source === 1 ? "Mobile App" : "Desktop App";
      },
    },
    {
      Header: "",
      accessor: (row) => (
        <OverlayTrigger
          trigger="click"
          placement="left"
          overlay={actionPopover(row)}
          show={popoverId === row?.id}
          rootClose
        >
          <div
            className="text-end"
            onClick={(event) => {
              event.stopPropagation();
              handleShowPopover(row?.id);
            }}
          >
            <DotIcon className="cursor-pointer" />
          </div>
        </OverlayTrigger>
      ),
    },
  ];

  const actionPopover = (row) => (
    <Popover id={`popover-${row?.id}`}>
      <Popover.Body className="admin-popover">
        <div className="popover-item" onClick={() => handleResend(row)}>
          Resend
        </div>
        {row?.can_delete && (
          <div className="popover-item" onClick={() => handleDelete(row?.id)}>
            Delete
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  const handleClickOutside = () => {
    if (popoverId) {
      setPopoverId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popoverId]);

  useEffect(() => {
    fetchInvitiations();
  }, []);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredInvitations = allInvitiations.filter((invitation) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        if (eventKey === "1") {
          return (
            invitation.email &&
            invitation.email?.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "2") {
          return (
            invitation.organization_name &&
            invitation.organization_name?.toLowerCase() ===
              filter.name?.toLowerCase()
          );
        } else if (eventKey === "3") {
          return invitation.invites && invitation.invites === filter.name;
        } else if (eventKey === "4") {
          return (
            invitation.Expired &&
            invitation.Expired?.toLowerCase() === filter.name?.toLowerCase()
          );
        } else if (eventKey === "5") {
          const userAccess = invitation?.inv_source === 1 ? "Mobile App" : "Desktop App";
          return userAccess === filter.name;
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "email":
                return applyCondition(
                  invitation.email?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "org_name":
                return applyCondition(
                  invitation.organization_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "invites":
                return applyCondition(
                  `${invitation.invites}`,
                  condition,
                  value
                );
              case "expired":
                return applyCondition(
                  invitation.Expired?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "user_access":
                const userAccess = invitation?.inv_source === 1 ? "Mobile App" : "Desktop App";
                return applyCondition(
                  userAccess.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (invitation?.email &&
        invitation?.email.toLowerCase().includes(searchValue.toLowerCase())) ||
      (invitation?.organization_name &&
        invitation?.organization_name
          .toLowerCase()
          .includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  return (
    <>
      <div className="base-filter-container overflow-hidden" style={{alignItems: "baseline"}}>
        <div className="left-filter">
          <BaseFilter
            tabs={tabs}
            className="mb-3"
            totalRecords={filteredInvitations.length}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            showExport={false}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            isSearch={true}
            tableData={filteredInvitations}
          />
        </div>
        <div
          onClick={handleShowAddModal}
          className="add-button d-flex align-items-center mb-2"
        >
          <span className="mb-1">
            <AddIcon />
          </span>
          <span className="add-organization ms-2">Invite User</span>
        </div>
      </div>
      <div>
        <BaseTable
          className="mt-3 mb-3"
          columns={columns}
          data={filteredInvitations}
          selectable={false}
          showCheckboxes={false}
          action={false}
          isStickyScroll
        />

        <InviteNewUserModal
          show={showAddModal}
          handleClose={handleCloseAddModal}
          fetchData={fetchInvitiations}
        />

        <DeleteModal
          showModal={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          title={"Are you sure you want to delete the invitation?"}
          subTitle={"Deleting the invitation is irreversible"}
        />
        <InvitationModal
          title="Invitation was resent successfully "
          show={showSuccessModal}
          onClose={closeSuccessModal}
        />
      </div>
    </>
  );
};

export default Invitaion;
