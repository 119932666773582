import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Row,
} from "react-bootstrap";
import Moment from "react-moment";
import { decodeHTML } from "entities";
import { Link } from "react-router-dom";
import axios from "../../util/axios";
import { ReactComponent as NewspaperIcon } from "../../assets/images/nav-icon-newspaper.svg";
import {
  showPromiseToast,
  showToast,
  showUpdateToast,
} from "../../util/toasts";

const ExportLeaks = ({ keyword }) => {
  const [controller, setController] = useState(new AbortController());
  const [progress, setProgress] = useState(0);
  const sanitizeString = (str) => {
    return str
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading/trailing spaces
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9-]/g, "-") // Replace special characters with hyphens
      .replace(/-+/g, "-"); // Remove duplicate hyphens
  };
  const downloadPdf = async () => {
    const toastId = showToast(
      `Generating PDF...`,
      "info",
      "top-center",
      "light",
      { isLoading: true },
      false
    );
    try {
      const response = await axios.get("leaks/export", {
        params: { keyword },
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
          showUpdateToast(
            toastId,
            `Download is Progress ${percentCompleted}%`,
            "info",
            true
          );
        },
      });

      // Create a Blob URL
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor tag to trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = sanitizeString(keyword + "-leaks");
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      showUpdateToast(
        toastId,
        "Leaks PDF downloaded successfully",
        "success",
        false
      );
    } catch (error) {
      console.error("Download failed", error);
      showUpdateToast(
        toastId,
        `${error?.response?.data?.message || "Failed to generate pdf."}`,
        "error",
        false,
        { className: "toast-report" }
      );
    }
  };

  return (
    <React.Fragment>
      <Button variant="text" className="btn" onClick={downloadPdf}>
        Download PDF
      </Button>
    </React.Fragment>
  );
};

export default ExportLeaks;
