export const conditionMapping = {
  ip: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
  ],
  port: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  service: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  product: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "is empty", value: "is_empty" },
    { label: "is not empty", value: "is_not_empty" },
  ],
  is_encrypted: [{ label: "is", value: "is" }],
  current_state: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  current_status: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  host: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  hostname: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  domain: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  email: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  password: [{ label: "is", value: "is" }],
  hashed_password: [{ label: "is", value: "is" }],
  database_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  pass_auth_method: [{ label: "is", value: "is" }],
  auth_methods: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  creds: [{ label: "is", value: "is" }],
  has_hosting_provider: [{ label: "is", value: "is" }],
  hosting_provider: [
    { label: "is", value: "is" },
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  has_waf: [{ label: "is", value: "is" }],
  waf_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  manufacturer: [
    { label: "is", value: "is" },
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  has_cms: [{ label: "is", value: "is" }],
  cms_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  has_cdn: [{ label: "is", value: "is" }],
  cdn_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  is_spf_secured: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  is_dmarc_secured: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  is_secured: [{ label: "is", value: "is" }],
  common_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  is_expired: [{ label: "is", value: "is" }],
  web_interface: [{ label: "is", value: "is" }],
  issuer: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  days: [
    { label: "are", value: "are" },
    { label: "are not", value: "are_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  ciphers: [{ label: "are", value: "are" }],
  protocols: [{ label: "are", value: "are" }],
  status: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  hosting_provider: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  cdn_name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  hosting_provider_discovery: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  cdn_name_discovery: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  number_issues: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  registrar: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  dnssec: [{ label: "is", value: "is" }],
  nameservers: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  exploitability: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  impact: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  risk_score: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  issue_name: [
    { label: "is", value: "is" },
    { label: "starts with", value: "starts_with" },
    { label: "contains", value: "contains" },
  ],
  asset_criticality: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  days_open: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  cvss: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  epss: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  cisa_kev: [{ label: "is", value: "is" }],
  armory_tested: [{ label: "is", value: "is" }],
  is_war_room: [{ label: "is", value: "is" }],
  has_cve: [{ label: "is", value: "is" }],
  issue_status: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  discovered_by: [{ label: "is", value: "is" }],
  network_zone: [{ label: "is", value: "is" }],
  criticality: [{ label: "is", value: "is" }],
  service_category: [{ label: "is", value: "is" }],
  first_detected: [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  host_status_code: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  first_seen_at: [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  last_seen_at: [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  registration_date: [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  validity: [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  https: [{ label: "is", value: "is" }],
  http: [{ label: "is", value: "is" }],
  login_page: [
    { label: "is", value: "is" },
    { label: "contains", value: "contains" },
    { label: "ends with", value: "ends_with" },
  ],
  name: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  url: [
    { label: "is", value: "is" },
    { label: "contains", value: "contains" },
  ],
  title: [
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  description: [
    { label: "contains", value: "contains" },
    { label: "doesn’t contains", value: "does_not_contain" },
  ],
  root_domain: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  subdomain: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  hosting: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  cdn: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  scan_category: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],

  org_name: [
    { label: "is", value: "is" },
    { label: "starts with", value: "starts_with" },
    { label: "contains", value: "contains" },
  ],
  target: [
    { label: "is", value: "is" },
    { label: "starts with", value: "starts_with" },
    { label: "contains", value: "contains" },
  ],
  spoofing: [{ label: "is", value: "is" }],
  low: [{ label: "is", value: "is" }],
  takeover: [{ label: "is", value: "is" }],
  full_name: [
    { label: "is", value: "is" },
    { label: "starts with", value: "starts_with" },
    { label: "contains", value: "contains" },
  ],
  role: [{ label: "is", value: "is" }],
  type: [{ label: "is", value: "is" }],
  users_count: [{ label: "is", value: "is" }],
  usedLimit: [{ label: "is", value: "is" }],
  alert_limit: [{ label: "is", value: "is" }],
  ip_limit: [{ label: "is", value: "is" }],
  expired: [{ label: "is", value: "is" }],
  invites: [{ label: "is", value: "is" }],
  report_name: [
    { label: "is", value: "is" },
    { label: "starts with", value: "starts_with" },
    { label: "contains", value: "contains" },
  ],
  seen: [{ label: "is", value: "is" }],
  is_new: [{ label: "is", value: "is" }],
  // v2
  Hostname: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  Domain: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
    { label: "ends with", value: "ends_with" },
    { label: "contains", value: "contains" },
  ],
  "IP Address": [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "starts with", value: "starts_with" },
  ],
  Status: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  "Hosting Provider": [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  CDN: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  "Network Zone": [{ label: "is", value: "is" }],
  "Service Category": [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  "Web Interface": [{ label: "is", value: "is" }],
  "Asset Criticality": [{ label: "is", value: "is" }],
  "Security Issues": [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
    { label: "above", value: "above" },
    { label: "below", value: "below" },
  ],
  "Discovered By": [{ label: "is", value: "is" }],
  "First Detected": [
    { label: "last 24 hours", value: "last_24_hours" },
    { label: "last 7 days", value: "last_7_days" },
    { label: "last 30 days", value: "last_30_days" },
    { label: "from", value: "from" },
    { label: "until", value: "until" },
    { label: "between", value: "between" },
  ],
  "Current State": [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" },
  ],
  user_access: [
    { label: "is", value: "is" },
    { label: "is not", value: "is_not" }
  ],
};

export const getConditionsForColumn = (column) => {
  return conditionMapping[column];
};

export const parseFilterString = (filterString) => {
  const rules = filterString.split("|");
  return rules.map((rule) => {
    const [column, condition, value] = rule.split(",");
    return { column, condition, value };
  });
};

export const applyCondition = (fieldValue, condition, value) => {
  let res = false;
  try {
    const currentDate = new Date();
    const fieldDate = new Date(fieldValue);

    const calculatePastDate = (days) => {
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - days);
      return pastDate;
    };

    switch (condition) {
      case "is":
        res = fieldValue === value;
        break;
      case "is_not":
        res = fieldValue !== value;
        break;
      case "starts_with":
        res = fieldValue?.startsWith(value);
        break;
      case "ends_with":
        res = fieldValue?.endsWith(value);
        break;
      case "contains":
        res = fieldValue?.includes(value);
        break;
      case "does_not_contain":
        res = !fieldValue?.includes(value);
        break;
      case "is_empty":
        res = !fieldValue || fieldValue?.trim() === "";
        break;
      case "is_not_empty":
        res = fieldValue && fieldValue?.trim() !== "";
        break;
      case "are":
        res = fieldValue == value;
        break;
      case "are_not":
        res = fieldValue != value;
        break;
      case "above":
        res = parseFloat(fieldValue) > parseFloat(value);
        break;
      case "below":
        res = parseFloat(fieldValue) < parseFloat(value);
        break;
      case "last_24_hours":
        const last24Hours = calculatePastDate(1);
        res = fieldDate >= last24Hours && fieldDate <= currentDate;
        break;
      case "last_7_days":
        const last7Days = calculatePastDate(7);
        res = fieldDate >= last7Days && fieldDate <= currentDate;
        break;
      case "last_30_days":
        const last30Days = calculatePastDate(30);
        res = fieldDate >= last30Days && fieldDate <= currentDate;
        break;
      case "from":
        const fromDate = new Date(value);
        res = fieldDate >= fromDate && fieldDate <= currentDate;
        break;
      case "until":
        const untilDate = new Date(value);
        res = fieldDate >= untilDate && fieldDate <= currentDate;
        break;
      case "between":
        const [startDate, endDate] = value
          .split(" - ")
          .map((date) => new Date(date));
        res = fieldDate >= startDate && fieldDate <= endDate;
        break;
      default:
        return false;
    }
  } catch (e) {
    console.error("Error: ", e);
  } finally {
    return res;
  }
};
