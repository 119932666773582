import React, { useState, useEffect } from "react";
import BaseFilter from "../../../../components/filter/BaseFilter";
import { ReactComponent as AddIcon } from "../../../../assets/images/add.svg";
import BaseTable from "../../../../components/table/BaseTable";
import BaseInput from "../../../../components/form/BaseInput";
import { ReactComponent as CommentIcon } from "../../../../assets/images/comment.svg";
import { ReactComponent as DotIcon } from "../../../../assets/images/threedots.svg";
import { ReactComponent as ConfirmIcon } from "../../../../assets/images/check.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/images/cancel.svg";
import { OverlayTrigger, Popover, Form } from "react-bootstrap";
import InviteNewUserModal from "../InviteNewUserModal";
import { showToast } from "../../../../util/toasts";
import DeleteModal from "../../../../components/modal/DeleteModal";
import axios from "../../../../util/axios";
import { formatDate } from "../../../../util/formatDate.js";
import { adminUserTabsConfig } from "../../../../util/tabsConfig.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";

const Users = ({ orgName, handleOrgName }) => {
  const [popoverId, setPopoverId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (users) => {
    const emails = [
      ...new Set(users.filter((user) => user?.email).map((user) => user.email)),
    ].map((email) => ({
      id: email,
      name: email,
      type: "Email: " + email,
      key: "1",
      active: false,
    }));

    const organizations = [
      ...new Set(
        users.filter((user) => user?.org_name).map((user) => user.org_name)
      ),
    ].map((org_name) => ({
      id: org_name,
      name: org_name,
      type: "Organization: " + org_name,
      key: "2",
      active: false,
    }));

    const roles = [
      ...new Set(users.filter((user) => user?.role).map((user) => user.role)),
    ].map((role) => ({
      id: role,
      name: role,
      type: "Role: " + role,
      key: "3",
      active: false,
    }));

    const fullNames = [
      ...new Set(
        users.filter((user) => user?.fullname).map((user) => user.fullname)
      ),
    ].map((fullname) => ({
      id: fullname,
      name: fullname,
      type: "Full Name: " + fullname,
      key: "4",
      active: false,
    }));

    const userAccess = [
      {
        id: "Desktop App",
        name: "Desktop App",
        type: "User Access: Desktop App",
        key: "5",
        active: false,
      },
      {
        id: "Mobile App", 
        name: "Mobile App",
        type: "User Access: Mobile App",
        key: "5",
        active: false,
      }
    ];

    setTabs(
      adminUserTabsConfig({
        emails,
        organizations,
        roles,
        fullNames,
        userAccess,
      })
    );
  };

  const handleShowAddModal = () => setShowAddModal(true);

  const handleCloseAddModal = () => setShowAddModal(false);

  const handleShowPopover = (id) => {
    setPopoverId((prevId) => (prevId === id ? null : id));
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
    setPopoverId(null);
  };

  const handleUnlockAccount = async (id) => {
    try {
      const response = await axios.post(
        `/user/unlock`,
        { user_id: id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status) {
        showToast(response.data.msg, "success");
        fetchUsers();
      } else {
        showToast(response.data.msg, "error");
      }
    } catch (error) {
      console.error("Error unlocking account:", error);
      const errorMessage =
        error.response?.data?.msg ||
        "Failed to unlock account. Please try again.";
      showToast(errorMessage, "error");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const { data } = await axios.delete(`admin/users/${deleteId}`);
      fetchUsers();
      setShowDeleteModal(false);
      showToast("User Deleted Successfully", "success");
    } catch (error) {
      setShowDeleteModal(false);
      console.error("Error:", error);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId(null);
  };

  const handleEdit = (id) => {
    setId(id);
    setIsEdit(true);
    const currentRow = data.find((row) => row.id === id);
    setEditedRow(currentRow);
  };

  const handleFieldChange = (field, value) => {
    setEditedRow((prevRow) => ({ ...prevRow, [field]: value }));
  };

  const handleConfirmEdit = async () => {
    try {
      const { data } = await axios.post(`/admin/users/${editedRow?.id}`, {
        ...editedRow,
      });
      fetchUsers();
      setIsEdit(false);
      setId(null);
      setEditedRow(null);
      showToast("Changes saved successfully", "success");
    } catch (err) {
      const message = err?.response?.data?.[0]?.msg;
      if (message) {
        showToast(message, "error");
      }
      console.error("Error:", err);
    }
  };

  const handleCancelEdit = () => {
    setIsEdit(false);
    setId(null);
    setEditedRow(null);
  };

  const handleClickOutside = (event) => {
    if (popoverId) {
      setPopoverId(null);
    }
  };

  const fetchOrganization = async () => {
    try {
      const { data } = await axios.get("all-organizations");
      if (data) {
        setAllOrganizations(data);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get("/admin/users", {
        params: { page: 1, pageSize: 1000 },
      });
      if (data?.items) {
        const modifiedData = data?.items?.map((el) => {
          return {
            ...el,
            org_name: el?.organization?.org_name,
            role:
              el?.member_level == 9
                ? "Armory Admin"
                : el?.member_level == 0
                ? "User-Viewer"
                : el?.member_level == 2
                ? "User-Editor"
                : el?.member_level == 1
                ? "Armory Distributer"
                : "",
          };
        });
        updateTabsWithData(modifiedData);
        setData(modifiedData);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [popoverId]);
  const columns = [
    { Header: "Email", accessor: "email", isSortable: true },
    {
      Header: "Organization",
      key: "org_name",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 200,
            }}
          >
            <Form.Select
              defaultValue={editedRow?.organization?.id}
              style={{
                border: "1px solid white",
                height: "40px",
                color: "#ffffff",
              }}
              onChange={(e) =>
                handleFieldChange("organization_id", e.target.value)
              }
            >
              {allOrganizations?.map((el) => {
                return <option value={el.id}>{el?.org_name}</option>;
              })}
            </Form.Select>
          </div>
        ) : (
          <span>{row?.org_name || "-"}</span>
        ),
    },
    {
      Header: "Role",
      key: "role",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 150,
            }}
          >
            <Form.Select
              defaultValue={editedRow.member_level}
              style={{
                border: "1px solid white",
                height: "40px",
                color: "#ffffff",
              }}
              onChange={(e) =>
                handleFieldChange("member_level", e.target.value)
              }
            >
              <option value={9}>Armory Admin</option>
              <option value={1}>Armory Distributer</option>
              <option value={2}>User-Editor</option>
              <option value={0}>User-Viewer</option>
            </Form.Select>
          </div>
        ) : (
          <span>{row?.role || "-"}</span>
        ),
    },
    {
      Header: "Full Name",
      key: "fullname",
      isSortable: true,
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 150,
            }}
          >
            <BaseInput
              type="text"
              className="base-input"
              defaultValue={editedRow.fullname}
              onChange={(e) => handleFieldChange("fullname", e.target.value)}
            />
          </div>
        ) : (
          <span>{row?.fullname || "-"}</span>
        ),
    },
    {
      Header: "Phone Number",
      key: "phone",
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 170,
            }}
          >
            <BaseInput
              type="number"
              className="base-input"
              defaultValue={editedRow.phone}
              onChange={(e) => handleFieldChange("phone", e.target.value)}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "e") {
                  e.preventDefault();
                }
              }}
            />
          </div>
        ) : (
          <span>{row?.phone || "-"}</span>
        ),
    },
    {
      Header: "Registration Date",
      isSortable: true,
      key: "registration_date",
      accessor: (row) => {
        return <span>{formatDate(row?.registration_date)}</span>;
      },
    },
    {
      Header: "User Status",
      isSortable: true,
      key: "user_status",
      accessor: (row) => {
        return (
          <span>{row?.lock_status === "unlock" ? "Not Locked" : "Locked"}</span>
        );
      },
    },
      {
      Header: "User Access",
      key: "user_access",
      isSortable: true,
      accessor: (row) => {
        const invSource = row?.invitation?.inv_source;
        return invSource === 1 ? "Mobile App" : "Desktop App";
      },
    },
    {
      Header: "Comments",
      isSortable: true,
      key: "comments",
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div
            className="edit-input"
            style={{
              width: 230,
            }}
          >
            <BaseInput
              type="text"
              className="base-input"
              defaultValue={editedRow.comments}
              onChange={(e) => handleFieldChange("comments", e.target.value)}
            />
          </div>
        ) : (
          <span>
            {row?.comments ? (
              <span>
                <CommentIcon /> {row?.comments}
              </span>
            ) : (
              "-"
            )}
          </span>
        ),
    },
 
    {
      Header: "",
      accessor: (row) =>
        row && isEdit && id === row.id ? (
          <div className="d-flex gap-2">
            <span className="editicon" onClick={handleConfirmEdit}>
              <ConfirmIcon height={18} className="cursor-pointer" />
            </span>
            <span className="editicon" onClick={handleCancelEdit}>
              <CancelIcon height={15} className="cursor-pointer" />
            </span>
          </div>
        ) : (
          row && (
            <span>
              <OverlayTrigger
                trigger="click"
                placement="left"
                overlay={actionPopover(row.id, row.lock_status)}
                show={popoverId === row.id}
                rootClose
              >
                <div
                  className="text-end"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleShowPopover(row.id);
                  }}
                >
                  <DotIcon className="cursor-pointer" />
                </div>
              </OverlayTrigger>
            </span>
          )
        ),
    },
  ];

  useEffect(() => {
    fetchUsers();
    fetchOrganization();
  }, []);

  useEffect(() => {
    if (orgName) {
      setActiveFilters((currentFilters) =>
        webUpdatesFilter(
          orgName,
          currentFilters.filter((filter) => filter.eventKey !== "2"),
          "2",
          "Organization"
        )
      );
      handleOrgName("");
    }
  }, [orgName]);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredUsers = data.filter((user) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name?.toLowerCase();
        if (eventKey === "1") {
          return user.email && user.email?.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return (
            user.org_name && user.org_name?.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "3") {
          return user.role && user.role?.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "4") {
          return (
            user.fullname && user.fullname?.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "5") {
          const userAccess = user?.invitation?.inv_source === 1 ? "Mobile App" : "Desktop App";
          return userAccess === filter.name;
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "email":
                return applyCondition(
                  user.email?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "org_name":
                return applyCondition(
                  user.org_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "role":
                return applyCondition(
                  user.role?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "full_name":
                return applyCondition(
                  user.fullname?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "user_access":
                const userAccess = user?.invitation?.inv_source === 1 ? "Mobile App" : "Desktop App";
                return applyCondition(
                  userAccess.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (user?.email &&
        user?.email.toLowerCase().includes(searchValue.toLowerCase())) ||
      (user?.org_name &&
        user?.org_name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (user?.fullname &&
        user?.fullname.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  const actionPopover = (id, account_status) => (
    <Popover id={`popover-${id}`}>
      <Popover.Body className="admin-popover">
        <div className="popover-item" onClick={() => handleEdit(id)}>
          Edit
        </div>
        <div className="popover-item" onClick={() => handleDelete(id)}>
          Delete
        </div>
        <div
          className={`popover-item ${
            account_status === "unlock" ? "disabled" : ""
          }`}
          onClick={() => account_status !== "unlock" && handleUnlockAccount(id)}
          style={{
            pointerEvents: account_status === "unlock" ? "none" : "auto",
            opacity: account_status === "unlock" ? 0.5 : 1,
          }}
        >
          Unlock Account
        </div>
      </Popover.Body>
    </Popover>
  );

  const exportHeader = [
    "Email",
    "Organization",
    "Role",
    "Full Name",
    "Phone Number",
    "Registration Date",
    "User Access",
    "Comments"
  ];

  const exportRows = filteredUsers.map(user => [
    user.email || "",
    user.org_name || "",
    user.role || "",
    user.fullname || "",
    user.phone || "",
    formatDate(user.registration_date) || "",
    user.invitation?.inv_source === 1 ? "Mobile App" : "Desktop App",
    user.comments || ""
  ]);

  return (
    <>
      <div className="base-filter-container" style={{alignItems: "flex-start"}}>
        <div className="left-filter">
          <BaseFilter
            tabs={tabs}
            className="mb-3"
            totalRecords={filteredUsers.length}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
            removeFilter={removeFilter}
            showExport={true}
            exportTitle="Users"
            exportHeader={exportHeader}
            exportRows={exportRows}
            searchValue={searchValue}
            onSearchChange={handleSearchChange}
            isSearch={true}
            tableData={filteredUsers}
          />
        </div>
        <div
          className="add-button d-flex align-items-center" style={{marginTop: "21px"}}
          onClick={handleShowAddModal}
        >
          <span className="mb-1">
            <AddIcon />
          </span>
          <span className="add-organization ms-2">Invite User</span>
        </div>
      </div>
      <div>
        <div className="assets-discovery-table  custom-x-scroll-table">
          <BaseTable
            className="mt-3 mb-3"
            columns={columns}
            data={filteredUsers}
            selectable={false}
            showCheckboxes={false}
            action={false}
            isStickyScroll
          />
        </div>
        <InviteNewUserModal
          show={showAddModal}
          handleClose={handleCloseAddModal}
        />
        <DeleteModal
          showModal={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          title={"Are you sure you want to delete this user?"}
          subTitle={"Deleting the user is irreversible"}
        />
      </div>
    </>
  );
};

export default Users;
